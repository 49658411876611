<template>
    <section class="pt-lg-8 pt-4 place-holder-white">
        <div class="container full-height pt-lg-4 pt-8">

            <login-heading heading="Verify your Mobile Number">
                <template #info>
                    <div class="w-35p-res">
                        <p class="d-inline mr-lg-1 mb-3">An OTP has been sent to your registered mobile number
                            <span class="font-inter-medium">+91 {{ phone }}.</span>
                        </p>
                        <p>Please enter the OTP below to verify your number.</p>
                    </div>
                </template>
            </login-heading>

            <s-form class="row">
                <div class="col-12 col-lg-4">
                    <validated-input :disabled="loading" v-model="model.otp" type="password" label="Enter OTP"
                                     class="c-input"></validated-input>

                    <p v-if="isInvalidOtp" class="text-danger">Invalid OTP</p>

                    <div class="fl-x fl-j-e">
                        <span class="font-inter-extra-light text-white fs--1">Not yet received ?
                            <p @click="resendOtp" class="ml-2 d-inline cursor-pointer link-underline pos-r text-white">Resend</p>
                        </span>
                    </div>

                    <p v-if="resendOtpMessage!==''" class="font-inter-extra-light text-white fs--1">{{resendOtpMessage}}</p>

                    <btn :disabled="loading" :loading="loading"
                         loading-text="Validating OTP..." @click="validateOtp" block text="Verify"
                         class="mt-4 py-3 border-r--2 auth-button"></btn>
                </div>
            </s-form>

        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'VerifyMobilePage',

    components: { LoginHeading },

    data () {
        return {
            phone: this.$route.params.phone,
            loading: false,
            resendLoading: false,
            isInvalidOtp: false,
            resendOtpMessage: '',
            model: {
                otp: ''
            }
        };
    },

    beforeMount () {
        this.loadData();
    },

    methods: {
        async loadData () {
            const response = await axios.get(urls.auth.status);
            if (response.data['public-key']) {
            } else {
            }
        },

        async resendOtp () {
            this.isInvalidOtp = false;
            this.resendLoading = true;
            const response = await axios.get(urls.auth.resendLoginOtp);
            const json = response.data;
            if (json.error === false) {
                this.resendOtpMessage = 'OTP has been successfully send to ' + this.phone + '.';
            } else {
                this.resendOtpMessage = 'Could not resend OTP, Please try again.';
            }
            this.resendLoading = false;
        },
        async validateOtp () {
            this.loading = true;
            this.isInvalidOtp = false;
            this.resendOtpMessage = '';
            const response = await axios.form(urls.auth.verifyLoginOtp, this.model, ['otp']);
            const json = response.data;
            if (json.error === true) {
                this.invalidOtp();
            } else if (json.error === false) {
                this.validOtp();
            } else {
                this.invalidOtp();
            }
            this.loading = false;
        },
        validOtp () {
            const baseUrl = process.env.VUE_APP_BASE_URL;
            if (this.$route.hash) {
                location.href = baseUrl + '/customer/' + this.$route.hash;
                // location.href = 'https://webuat.bankeauctionsindia.com/customer/' + this.$route.hash;
                // location.href = 'https://ventura.xeoscript.com/customer/' + this.$route.hash; // for xeo uat
            } else {
                location.href = baseUrl + '/customer/';
                // location.href = 'https://webuat.bankeauctionsindia.com/customer/';
                // location.href = 'https://ventura.xeoscript.com/customer/'; // for xeo uat
            }
        },
        invalidOtp () {
            this.isInvalidOtp = true;
        }
    }
};
</script>

<style scoped>

</style>
